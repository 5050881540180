import { graphql, PageProps } from "gatsby";
import React from "react";
import Layout from "../components/Layout";
import Gallery from "@browniebroke/gatsby-image-gallery";

const Family = ({ data }: PageProps<Queries.FamilyGalleryQuery>) => {
  const images = data.allCloudinaryMedia.edges
    .map(({ node }) => node)
    .filter((n) => n.cloudinaryData?.folder === "Wedding/family");
  console.log(images.length);
  return (
    <Layout>
      <Gallery images={images} />
    </Layout>
  );
};

export const pageQuery = graphql`
  query FamilyGallery {
    allCloudinaryMedia(
      filter: { cloudinaryData: { folder: { eq: "Wedding/family" } } }
    ) {
      edges {
        node {
          thumb: gatsbyImageData(width: 270, height: 270, placeholder: BLURRED)
          full: gatsbyImageData(
            transformations: ["q_auto"]
            # transformations: ["q_90:420"]
            layout: CONSTRAINED
            width: 1440
          )
          cloudinaryData {
            folder
          }
        }
      }
    }
  }
`;

export default Family;
